@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

.swiper-pagination-bullet {
  height: 5px !important;
  width: 5px !important;
  border-radius: 20px !important;
}

.swiper-pagination-bullet-active {
  background-color: #f17d28 !important;
  width: 14px !important;
}

/* 
*::-webkit-scrollbar {
  background-color: transparent ;
  width: 10px;
  overflow: overlay;
}


*::-webkit-scrollbar-track {
  background-color: transparent;
  overflow: overlay;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: rgba(241, 125, 40);
  background-clip: content-box;
} */
